import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-fremont-california.png'
import image0 from "../../images/cities/scale-model-of-patterson-house-in-fremont-california.png"
import image1 from "../../images/cities/scale-model-of-moffett-field-historical-society-museum-in-fremont-california.png"
import image2 from "../../images/cities/scale-model-of-deep-creek-park-in-fremont-california.png"
import image3 from "../../images/cities/scale-model-of-ulistac-natural-area-in-fremont-california.png"
import image4 from "../../images/cities/scale-model-of-central-park-in-fremont-california.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Fremont'
            state='California'
            image={image}
            lat='37.5482697'
            lon='-121.98857190000001'
            attractions={ [{"name": "Patterson House", "vicinity": "34600 Ardenwood Blvd, Fremont", "types": ["museum", "point_of_interest", "establishment"], "lat": 37.5579507, "lng": -122.04870840000001}, {"name": "Moffett Field Historical Society Museum", "vicinity": "500 Severyns Ave, Mountain View", "types": ["museum", "point_of_interest", "establishment"], "lat": 37.4112691, "lng": -122.05409959999997}, {"name": "Deep Creek Park", "vicinity": "Fremont", "types": ["park", "point_of_interest", "establishment"], "lat": 37.5732546, "lng": -122.05426940000001}, {"name": "Ulistac Natural Area", "vicinity": "4901 Lick Mill Blvd, Santa Clara", "types": ["park", "point_of_interest", "establishment"], "lat": 37.4038071, "lng": -121.95440859999997}, {"name": "Central Park", "vicinity": "40000 Paseo Padre Pkwy, Fremont", "types": ["park", "point_of_interest", "establishment"], "lat": 37.5477609, "lng": -121.96543830000002}] }
            attractionImages={ {"Patterson House":image0,"Moffett Field Historical Society Museum":image1,"Deep Creek Park":image2,"Ulistac Natural Area":image3,"Central Park":image4,} }
       />);
  }
}